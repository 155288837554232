import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import RightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import useStyles from "./style";
import { blogPosts, teams, features } from "../../constants";

const commonProps = {
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const Heading = ({ title, subTitle, id }) => (
  <Box textAlign="center" py={10} id={id}>
    <Typography variant="h3">
      <strong>{title}</strong>
    </Typography>
    <Typography variant="h6">
      <Box my={3}>{subTitle}</Box>
    </Typography>
  </Box>
);

const SeeMoreButton = ({ classes, ...otherProps }) => (
  <Grid item container justifyContent="center" xs={12}>
    <Button
      className={classes.moreBtn}
      variant="outlined"
      size="large"
      sx={{
        ":hover": {
          bgcolor: "primary.main",
          color: "white",
        },
      }}
      {...otherProps}
    >
      See More
    </Button>
  </Grid>
);

const FeaturedComponents = ({ classes }) => {
  return (
    <Paper id="featured-components" className={classes.featurePaper}>
      <Heading
        id="product-info"
        title="What is PADME?"
        subTitle="Information about our products"
      />
      <Container maxWidth="md">
        <div className={classes.videoContainer}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/TE4BltmYKmc"
            title="YouTube video player"
            style={{ border: 0 }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <SeeMoreButton
          classes={classes}
          component={RouterLink}
          to="/product-overview"
        />
      </Container>
      <Heading title="Featured Components" subTitle="Use our services" />
      <Box px={7}>
        <Grid container spacing={4} justifyContent="center">
          {features.map((feature) => (
            <Grid
              item
              container
              key={feature.title}
              justifyContent="center"
              md={6}
              lg={4}
              mb={5}
            >
              <Card className={classes.card} variant="outlined">
                <CardContent className={classes.cContent}>
                  <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold" }}>
                    {feature.title}
                  </Typography>
                  <Typography className="feature-desc" variant="body2">
                    {feature.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component="a"
                    fullWidth
                    variant="contained"
                    target="_blank"
                    className="btn-feature"
                    href={feature.link}
                    endIcon={<RightIcon />}
                  >
                    {feature.label}
                  </Button>
                  {feature.infoLink ? (
                    <Tooltip title="More information" placement="right">
                      <IconButton
                        href={feature.infoLink}
                        style={{ marginLeft: 0 }}
                      >
                        <InfoIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

const RecentNews = ({ classes }) => (
  <Container id="recent-news" className={classes.container}>
    <Heading title="Recent News" subTitle="What's currently going on!" />
    <Grid container spacing={3}>
      <Grid item container spacing={3} md={6}>
        <Grid item container justifyContent="center" xs={12}>
          <img
            src={blogPosts[0].image}
            alt={blogPosts[0].title}
            style={{ borderRadius: "20px" }}
            width={blogPosts[0].smallWidth ? "70%" : "100%"}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="div" variant="h4">
            <Box
              fontWeight="fontWeightBold"
              sx={{ ...commonProps, WebkitLineClamp: 1, mb: 1 }}
            >
              {blogPosts[0].title}
            </Box>
          </Typography>
          <Box>{blogPosts[0].date}</Box>
          <Typography component="div" variant="body2">
            <Box mb={2} fontWeight="fontWeightBold">
              By {blogPosts[0].author}
            </Box>
          </Typography>
          <Typography sx={{ ...commonProps, WebkitLineClamp: 2 }}>
            {blogPosts[0].description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container rowSpacing={5} md={6}>
        {blogPosts.slice(1, 4).map((news, ind) => (
          <Grid item display="flex" key={ind} xs={12}>
            <img
              className={classes.blogImg}
              alt={news.title}
              src={news.image}
            />
            <Box mx={5} sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <Typography noWrap variant="h6">
                <strong>{news.title}</strong>
              </Typography>
              <Typography component="div" variant="body2">
                <Box>{news.date}</Box>
                <Box mb={2} fontWeight="fontWeightBold">
                  By {news.author}
                </Box>
              </Typography>
              <Typography sx={{ ...commonProps, WebkitLineClamp: 3 }}>
                {news.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <SeeMoreButton
        classes={classes}
        component={RouterLink}
        to="/blog-and-news"
      />
    </Grid>
  </Container>
);

const Projects = ({ classes }) => {
  const projectsDump = [
    { image: "./assets/projects/SMITH.png", alt: "SMITH" },
    { image: "./assets/projects/polar_rgb.jpeg", alt: "POLAR_MI" },
    { image: "./assets/projects/cord_rgb.jpeg", alt: "CORD_MI" },
    { image: "./assets/projects/Leuko.jpeg", alt: "LEUKO_EXPERT" },
    { image: "./assets/projects/nfdi_health.jpeg", alt: "NFDI 4 HEALTH" },
    { image: "./assets/projects/fair_data.png", alt: "FAIR DATA SPACES" },
  ];

  return (
    <Box id="projects" bgcolor="white" pb={10}>
      <Container>
        <Heading
          title="Projects"
          subTitle="We are involved in several projects, where we apply our infrastructure."
        />
        <Grid container alignItems="center">
          {projectsDump.map((proj) => (
            <Grid item container justifyContent="center" key={proj.alt} xs={4}>
              <img src={proj.image} alt={proj.alt} width="50%" />
            </Grid>
          ))}
        </Grid>
        <SeeMoreButton
          classes={classes}
          component={RouterLink}
          to="/projects"
        />
      </Container>
    </Box>
  );
};

const Team = ({ classes }) => {
  return (
    <Container id="team" className={classes.container}>
      <Heading
        title="Our Team"
        subTitle={
          <>
            Meet the people behind <strong>PADME</strong>
          </>
        }
      />
      {teams.map((team, ind) => (
        <Box key={team.type} mb={ind + 1 < teams.length ? 10 : 0}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" mb={5}>
            {team.type}
          </Typography>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            textAlign="center"
          >
            {team.members.map((member) => (
              <Grid item key={member.name} xs={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    WebkitFilter: `${!team.active ? "grayscale(100%)" : null}`,
                  }}
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    style={{ borderRadius: 50, objectFit: "cover" }}
                    width="100"
                    height="100"
                  />
                  <Typography sx={{ mt: 2, fontWeight: "bold" }}>
                    {member.name}
                  </Typography>
                  <Typography variant="button">{member.role}</Typography>
                  <Typography variant="button" mt={2}>
                    {member.designation}
                  </Typography>
                  <Stack direction="row" mt={3} spacing={1}>
                    {member.social.map((soc) => (
                      <Tooltip title={soc.type} key={soc.link}>
                        <IconButton
                          target="_blank"
                          aria-label={soc.type}
                          href={soc.link}
                          key={soc.type}
                          sx={{ ":hover": { color: "primary.main" } }}
                        >
                          {soc.icon}
                        </IconButton>
                      </Tooltip>
                    ))}
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
};

const LandingPage = () => {
  const classes = useStyles();
  return (
    <>
      <Box id="main-prod-desc" className={classes.mainContainer}>
        <span className={classes.ribbon}></span>
        <Box display="flex" justifyContent="flex-end" pt={2} pr={8} mb={8}>
          <Button
            sx={{ color: "white", fontWeight: "bold" }}
            size="large"
            href="#product-info"
          >
            Product
          </Button>
          <Button
            sx={{ color: "white", fontWeight: "bold" }}
            size="large"
            href="https://git.rwth-aachen.de/groups/padme-development"
            target="_blank"
          >
            Code
          </Button>
          <Button
            sx={{ color: "white", fontWeight: "bold" }}
            size="large"
            href="#projects"
          >
            Projects
          </Button>
          <Button
            component={RouterLink}
            sx={{ color: "white", fontWeight: "bold" }}
            size="large"
            to="/publications"
          >
            Publications
          </Button>
          <Button
            sx={{ color: "white", fontWeight: "bold" }}
            size="large"
            href="#recent-news"
          >
            Blog & News
          </Button>
          <Button
            sx={{ color: "white", fontWeight: "bold" }}
            size="large"
            href="#team"
          >
            Team
          </Button>
        </Box>
        <Container>
          <Grid container>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              md={6}
              xs={12}
            >
              <img src="./assets/logo.png" alt="PADME Logo" width="70%" />
            </Grid>
            <Grid
              item
              container
              className={classes.mainTitle}
              alignItems="center"
              md={6}
              xs={12}
            >
              <Typography variant="h3">
                <strong>P</strong>latform for <br />
                <strong>A</strong>nalytics and <br />
                <strong>D</strong>istributed <br />
                <strong>M</strong>achine Learning for
                <br />
                <strong>E</strong>nterprises
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FeaturedComponents classes={classes} />
      <RecentNews classes={classes} />
      <Projects classes={classes} />
      <Team classes={classes} />
    </>
  );
};

export default LandingPage;
