import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import ScrollToTop from "../layout/ScrollToTop";
import Title from "../layout/Title";
import { blogPosts } from "../../constants";

const useStyles = makeStyles((theme) => ({
  blogImg: {
    width: "90%",
    height: 220,
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
  },
  imgGrid: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
      justifyContent: "center",
    },
  },
  textGrid: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      "& #blog-date": {
        justifyContent: "center",
      },
    },
  },
}));

const BlogsPage = () => {
  const classes = useStyles();

  return (
    <Container>
      <ScrollToTop />
      <Title text="Blog & News" mb={2} />
      <Typography variant="h6" mb={7}>
        Keep yourself up-to-date with the latest on PADME!
      </Typography>
      <Grid container>
        {blogPosts.map((blog) => (
          <Grid item container mb={7} xs={12} spacing={3} key={blog.title}>
            <Grid className={classes.imgGrid} item container md={3}>
              <img
                className={classes.blogImg}
                style={{ borderRadius: "20px", objectFit: "cover" }}
                src={blog.image}
                alt={blog.title}
              />
            </Grid>
            <Grid className={classes.textGrid} item md={9}>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                {blog.title}
              </Typography>
              <Typography id="blog-date" component="div" display="flex" mb={3}>
                {blog.date}
                <Box fontWeight="bold" ml={2}>
                  By {blog.author}
                </Box>
              </Typography>
              <Typography variant="h6">{blog.description}</Typography>
              {blog.bigImage && (
                <Box mt={3}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={blog.bigImage}
                    alt={blog.title}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogsPage;
