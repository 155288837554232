import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import ScrollToTop from "../layout/ScrollToTop";
import Title from "../layout/Title";
import { RenderLink } from "../../utils";

const ProjectsPage = () => {
  const projectsDump = [
    {
      image: "./assets/projects/SMITH.png",
      alt: "SMITH",
      details:
        "The aim within the scope of SMITH is the development of an innovative structure for cross-institutional connectivity and exchange of healthcare and research data between sites and beyond the consortium.",
      site: "http://www.smith.care/",
    },
    {
      image: "./assets/projects/polar_rgb.jpeg",
      alt: "POLAR_MI",
      details:
        "The “POLypharmazie, Arzneimittelwechselwirkungen, Risiken” use case encompasses all four consortia of the Medical Informatics Initiative and aims to contribute to the detection of health risks in patients with polymedication.",
      site: "https://www.gesundheitsforschung-bmbf.de/de/konsortien-ubergreifender-use-case-polar-mi-polypharmazie-arzneimittelwechselwirkungen-11056.php",
    },
    {
      image: "./assets/projects/cord_rgb.jpeg",
      alt: "CORD_MI",
      details:
        "The “Collaboration on Rare Diseases” use case is a joint project comprising the four consortia of the Medical Informatics Initiative, in which numerous German university hospitals and partner institutions are involved. The aim is to improve care and research in the field of rare diseases.",
      site: "https://www.gesundheitsforschung-bmbf.de/de/konsortien-ubergreifender-use-case-cord-mi-collaboration-on-rare-diseases-11072.php",
    },
    {
      image: "./assets/projects/Leuko.jpeg",
      alt: "LEUKO",
      details:
        "The main aim of the project is to develop machine learning models for the family of leukodystrophies that can be used in a diagnostic support system. Models will be built using clinical, genetic and image data (MRI) from three centers for rare diseases.",
      site: "https://leukoexpert.hs-mittweida.de/",
    },
    {
      image: "./assets/projects/nfdi_health.jpeg",
      alt: "NFDI",
      details:
        "The project aim to implement a federated health data infrastructure to foster data sharing and cooperation between clinical research, epidemiological and public health communities in Germany.",
      site: "https://www.nfdi4health.de/",
    },
    {
      image: "./assets/projects/fair_data.png",
      alt: "FAIR_DATA",
      details:
        "The FAIR Data Spaces project is funded by the German Federal Ministry of Education and Research, BMBF in the period from May 2021 to May 2024. In the project, the federated, secure data infrastructure Gaia-X and NFDI will be connected to form a common, cloud-based data space for industry and research in compliance with the FAIR principles. This means that data will be shared in a way that is findable, accessible, interoperable, and reusable.",
      site: "https://www.nfdi.de/fair-data-spaces/",
    },
    {
      image: "./assets/projects/BETTER.png",
      alt: "BETTER",
      details:
        "The Better Project is funded by the European Union’s Horizon program and the UK Research and Innovation Program. Its aim is to support the improvement of the European citizen’s health by creating a robust decentralised infrastructure which will enable healthcare professionals to exploit the full potential of larger sets of multi-source health data via tailor-made AI tools useful to compare, integrate, and analyse in a secure, cost-effective fashion across national borders, fully complying with present GDPR privacy guidelines.",
      site: "https://www.better-health-project.eu/",
    },    
  ];

  return (
    <Container>
      <ScrollToTop />
      <Title text="Projects" mb={2} />
      <Typography variant="h6" mb={7}>
        We are involved in several projects, where we apply our infrastructure.
      </Typography>
      <Grid container p={4} sx={{ background: "white", borderRadius: "20px" }}>
        {projectsDump.map((proj) => (
          <Grid
            item
            container
            key={proj.alt}
            alignItems="center"
            xs={12}
            py={3}
          >
            <Grid item xs={3}>
              <img src={proj.image} alt={proj.alt} width="70%" />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">
                {proj.details}
                <strong>
                  {" "}
                  Click <RenderLink href={proj.site} text="here" /> for project
                  website.
                </strong>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProjectsPage;
